/**
 * element UI 初始化文件，根据需要引入对应的组件
 * 完整组件列表以 https://github.com/element-plus/element-plus/tree/dev/packages 为准
 * @Author yuanyexu
 * @Date 2022-11-21
 */
import { App, Plugin } from "vue";
import {
  ElRow,
  ElCol,
  ElCard,
  ElMenu,
  ElSubMenu,
  ElMenuItem,
  ElInput,
  ElTable,
  ElTableColumn,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElLoading,
  ElMessageBox,
  ElMessage,
  ElScrollbar,
  ElButton,
  ElButtonGroup,
  ElSelect,
  ElOption,
  ElOptionGroup,
  ElRadio,
  ElRadioGroup,
  ElRadioButton,
  ElPagination,
  ElTooltip,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElAvatar,
  ElDatePicker,
  ElTag,
  ElDialog,
  ElPopconfirm,
  ElPopper,
  ElTimeline,
  ElTimelineItem,
  ElSwitch,
  ElLink,
  ElTabs,
  ElTabPane,
  ElAffix,
  ElTree,
  ElCheckbox,
  ElAutocomplete,
  ElCollapse,
  ElCollapseItem,
  ElUpload,
  ElInputNumber,
  ElIcon,
  ElPageHeader,
  ElConfigProvider,
  ElForm,
  ElFormItem,
  ElEmpty,
  ElColorPicker,
  ElText,
  ElSpace,
  ElAlert,
  ElDrawer,
  ElBadge,
} from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

/**
 * 初始化element-plus组件
 * @param {object} app Vue实例对象
 */
const initElementComponents = function (app: App<Element>, type?: any): void {
  let components = [
    ElRow,
    ElCol,
    ElCard,
    ElAvatar,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElButton,
    ElButtonGroup,
    ElDatePicker,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElInput,
    ElMenu,
    ElSubMenu,
    ElMenuItem,
    ElOption,
    ElOptionGroup,
    ElPagination,
    ElRadio,
    ElRadioButton,
    ElRadioGroup,
    ElScrollbar,
    ElSelect,
    ElTable,
    ElTableColumn,
    ElTooltip,
    ElTag,
    ElDialog,
    ElPopconfirm,
    ElPopper,
    ElTimeline,
    ElTimelineItem,
    ElSwitch,
    ElLink,
    ElTabs,
    ElTabPane,
    ElAffix,
    ElTree,
    ElCheckbox,
    ElAutocomplete,
    ElCollapse,
    ElCollapseItem,
    ElUpload,
    ElInputNumber,
    ElIcon,
    ElConfigProvider,
    ElPageHeader,
    ElForm,
    ElFormItem,
    ElEmpty,
    ElColorPicker,
    ElText,
    ElSpace,
    ElAlert,
    ElDrawer,
    ElBadge,
  ];
  if (type === "page") {
    components = [ElButton, ElEmpty, ElIcon];
  }
  const plugins = [ElLoading, ElMessage, ElMessageBox];

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }
  components.forEach((component) => {
    app.component(component.name, component);
  });
  plugins.forEach((component) => {
    app.use(component as Plugin);
  });
  // 利用provide和inject下发全局方法
  app.provide("$message", ElMessage);
  app.provide("$messageBox", ElMessageBox);
};

export { initElementComponents };
