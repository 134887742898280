
import { defineComponent } from "vue";
import zhCn from "element-plus/es/locale/lang/zh-cn";
export default defineComponent({
  setup() {
    return {
      zhCn,
    };
  },
});
