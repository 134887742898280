import { createRouter, createWebHistory } from "vue-router";
import { start, stop } from "@/libs/nprogress";

// 路由未登录放行白名单
export const routes = [
  {
    path: "/page",
    name: "page-index",
    meta: { hidden: true, title: " " },
    component: () => import("@/views/goods/landpage/index.vue"),
  },
  {
    path: "/order",
    name: "page-order",
    meta: { hidden: true, title: " " },
    component: () => import("@/views/goods/landpage/order.vue"),
  },
  {
    path: "/alipay",
    name: "page-alipay",
    meta: { hidden: true, title: " " },
    component: () => import("@/views/goods/landpage/alipay.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {
      el: "#app",
      top: 0,
      left: 0,
      behavior: "smooth",
    };
  },
} as any);

router.beforeEach((to, from, next) => {
  start();
  next();
});
router.afterEach((to, from) => {
  stop();
});

export default router;
