import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({
  showSpinner: false,
  easing: "ease",
  speed: 500,
});

// 开启进度条
export const start = () => {
  NProgress.start();
};
// 关闭进度条
export const stop = () => {
  NProgress.done();
};
